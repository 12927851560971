import React from "react"
import { useMetadata } from "../data/useMetadata"
import PropTypes from "prop-types"
import Modal from "./Modal"

const Telephone = ({ cta }) => {
  const { link, title, gtmClass } = cta
  const reg = /^tel:\+886-/
  const temp = link.replace(reg, "0")
  const phone = temp.slice(0, -4) + "-" + temp.slice(-4)

  return (
    <>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={`cta mx-auto rounded-full bg-gold-500 py-4 px-12 text-center text-white hover:bg-gold-400 lg:hidden ${gtmClass}`}
      >
        {title}
      </a>
      <Modal type="tel" phone={phone}>
        <button
          className={`cta mx-auto hidden rounded-full bg-gold-500 py-4 px-12 text-center text-white hover:bg-gold-400 lg:inline-block ${gtmClass}`}
        >
          {title}
        </button>
      </Modal>
    </>
  )
}
const Line = ({ cta }) => {
  const { link, title, gtmClass } = cta
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={`cta mx-auto rounded-full bg-gold-500 py-4 px-12 text-center text-white hover:bg-gold-400 ${gtmClass}`}
    >
      {title}
    </a>
  )
}

export const Index = ({ ctas, ctaTitle }) => {
  const isTel = ctaTitle === "CTA_telephone"
  const { gtmId } = useMetadata()

  const ctaContent = ctas?.map((cta, index) => {
    if (cta.gtm === gtmId) {
      return (
        <div key={index} className="my-6 text-center">
          {isTel ? <Telephone cta={cta} /> : <Line cta={cta} />}
        </div>
      )
    }
  })
  return <div>{ctaContent}</div>
}

Telephone.propTypes = {
  cta: PropTypes.object,
}
Line.propTypes = {
  cta: PropTypes.object,
}
Index.propTypes = {
  ctas: PropTypes.any,
  ctaTitle: PropTypes.string,
}
export default Index
