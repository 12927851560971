import React from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight"
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft"
import Arrow from "./Arrow"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const LawyerSlider = ({ data }) => {
  const arrowStyle = "text-white bg-gray-300 hover:bg-gray-400"
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: false,
    swipeToSlide: true,
    prevArrow: (
      <Arrow
        arrowIcon={FaArrowLeft}
        arrowClass={`-left-16 top-32 ${arrowStyle}`}
      />
    ),
    nextArrow: (
      <Arrow
        arrowIcon={FaArrowRight}
        arrowClass={`-right-16 top-32 ${arrowStyle}`}
      />
    ),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          centerPadding: "144px",
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "24px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const sliderItems = data.map((item, index) => {
    const {
      introduction: { introduction },
      name,
      profile: { gatsbyImageData },
      slug,
      practiceAreas,
    } = item

    return (
      <div className="mx-auto mb-5 h-full px-2 lg:px-6" key={index}>
        <div className="h-full rounded-lg bg-white p-6 leading-normal shadow">
          <div className="mb-5 flex">
            <GatsbyImage
              alt={name}
              image={gatsbyImageData}
              className="z-10 mr-4 h-16 w-16 overflow-hidden rounded-full"
            />
            <div>
              <p className="mb-1 text-lg font-bold text-gray-700">{name}</p>
              <p className="text-gray-600 line-clamp-1">
                {practiceAreas[0].title}執業律師
              </p>
            </div>
          </div>
          <p className="mb-2 h-[120px] text-gray-700 line-clamp-5">
            {introduction}
          </p>
          <Link to={`/lawyers/${slug}`} className="text-sm text-gray-600">
            更多資料
          </Link>
        </div>
      </div>
    )
  })
  return (
    <div className="lg:px-16">
      <Slider className="py-1" {...settings}>
        {sliderItems}
      </Slider>
    </div>
  )
}

LawyerSlider.propTypes = {
  data: PropTypes.array,
}

export default LawyerSlider
