import { graphql, useStaticQuery } from "gatsby"

export const useEmptyPostTag = () => {
  const tags = useStaticQuery(
    graphql`
      {
        allContentfulTag {
          group(field: contentful_id) {
            edges {
              node {
                slug
                post {
                  title
                }
              }
            }
          }
        }
      }
    `
  )
  const nullTagsList = []
  tags.allContentfulTag.group.forEach(edges => {
    const {
      node: { slug, post },
    } = edges.edges[0]
    if (post === null) {
      nullTagsList.push(slug)
    }
  })
  return nullTagsList
}
