import { Link } from "gatsby"
import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import useOnClickOutside from "../data/useOutsideClick"
import useHover from "../data/useHover"
import { useTopicsTags } from "../data/useTopicsTags"
import { useLawyer } from "../data/useLawyer"
import { usePeople } from "../data/usePeople"
import { FaAngleDown } from "@react-icons/all-files/fa/FaAngleDown"
import { FaAngleUp } from "@react-icons/all-files/fa/FaAngleUp"
import { FaBars } from "@react-icons/all-files/fa/FaBars"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Transition } from "@tailwindui/react"
import TagsCloud from "./TagsCloud"
import Button from "./Button"

const MenuItem = ({ children, overlay }) => {
  const [hoverRef, isHovered] = useHover()
  return (
    <div
      ref={hoverRef}
      className="h-full flex-wrap items-center text-gray-700 hover:border-gray-800 hover:text-gray-600"
    >
      <div className="flex h-full items-center px-2">
        {children}
        <FaAngleDown
          className="ml-1 inline-block fill-current text-gold-400"
          size={24}
        />
      </div>
      <div className={`relative border-t-2 ${isHovered ? "block" : "hidden"}`}>
        <div className="overflow-hidden rounded-b-lg">
          <div className="relative grid bg-white py-2">{overlay}</div>
        </div>
      </div>
    </div>
  )
}
MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  overlay: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
}

const Menubar = () => {
  const { allContentfulTopic } = useTopicsTags()

  const Items = allContentfulTopic.nodes.map(topic => {
    return (
      <Link
        key={topic.slug}
        to={`/blog/${topic.slug}/all`}
        className="my-1 px-3 font-semibold text-gray-700 hover:text-gray-900"
        activeClassName={`text-gold-400`}
      >
        {topic.title}
      </Link>
    )
  })

  return (
    <div className="hidden h-16 cursor-pointer items-center lg:block">
      <MenuItem overlay={Items}>法律知識文章</MenuItem>
    </div>
  )
}

const SidebarItem = ({ children, overlay }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()
  useOnClickOutside(ref, () => {
    setIsOpen(false)
  })
  return (
    <div ref={ref}>
      <button
        onClick={() => setIsOpen(prevState => !prevState)}
        className="group flex w-full items-center justify-between border-b py-3 text-base font-medium leading-6 text-gray-700 focus:outline-none"
      >
        {children} {isOpen ? <FaAngleUp /> : <FaAngleDown />}
      </button>
      <div className={`${isOpen ? "flex flex-wrap" : "hidden"}`}>{overlay}</div>
    </div>
  )
}
SidebarItem.propTypes = {
  children: PropTypes.node.isRequired,
  overlay: PropTypes.element,
}

const Sidebar = ({ pathname }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const { allContentfulTopic: sortTopic } = useTopicsTags()
  const { lawyerContactInformation } = useLawyer()
  const { peopleContactInformation } = usePeople()
  const isLanding = pathname === "/lawyers/landing"

  const ContactButton = () => {
    const info = isLanding ? lawyerContactInformation : peopleContactInformation
    const { lineAt, gtmClass } = info
    const text = isLanding ? "成為平台律師" : "免費法律諮詢"

    return (
      <a href={lineAt} target="_blank" rel="noopener noreferrer">
        <Button className={`my-5 w-full ${gtmClass}`}>{text}</Button>
      </a>
    )
  }
  const Items = sortTopic.nodes.map(topic => {
    const tags = topic.tag
    const cloud = <TagsCloud tags={tags} />
    return (
      <SidebarItem key={topic.id} overlay={cloud}>
        {topic.title}
      </SidebarItem>
    )
  })

  return (
    <div className="block lg:hidden">
      <button
        className="border-gray-200 text-gold-400 focus:bg-gray-100 focus:text-gray-600 focus:outline-none"
        aria-label="Open sidebar"
        onClick={() => setIsSidebarOpen(prevState => !prevState)}
      >
        <FaBars className="h-6 w-6" />
      </button>

      <Transition
        show={isSidebarOpen}
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </div>
        </div>
      </Transition>

      <Transition
        show={isSidebarOpen}
        enter="transform transition ease-in-out duration-500"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-500"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <section className="absolute inset-y-0 right-0 flex max-w-full pl-10">
              <div className="w-screen max-w-md">
                <div className="flex h-full flex-col space-y-6 overflow-y-scroll bg-white py-6 shadow-xl">
                  <header className="px-4 lg:px-6">
                    <div className="flex items-start justify-between space-x-3">
                      <div className="flex h-7 items-center">
                        <button
                          aria-label="Close panel"
                          className="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500"
                          onClick={() =>
                            setIsSidebarOpen(prevState => !prevState)
                          }
                        >
                          <FaTimes size={24} className="text-gold-400" />
                        </button>
                      </div>
                    </div>
                  </header>
                  <div className="relative flex-1 px-4">
                    <div className="absolute inset-0 px-4">
                      <div className="h-full">
                        {Items}
                        <ContactButton />
                        <Link
                          to="/about"
                          className={`mb-5 block cursor-pointer rounded bg-white py-2 px-4 text-center font-semibold text-gray-600`}
                        >
                          關於我們
                        </Link>
                        <Link
                          to="/find_lawyer"
                          className={`mb-5 block cursor-pointer rounded bg-white py-2 px-4 text-center font-semibold text-gray-600`}
                        >
                          律師名錄
                        </Link>
                        <Link
                          to="/lawyers/landing"
                          className={`block cursor-pointer rounded bg-white py-2 px-4 text-center font-semibold text-gray-600`}
                        >
                          我是律師
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Transition>
    </div>
  )
}

Sidebar.propTypes = {
  pathname: PropTypes.string,
}

const Menu = ({ pathname }) => {
  return (
    <>
      <Menubar />
      <Sidebar pathname={pathname} />
    </>
  )
}

Menu.propTypes = {
  pathname: PropTypes.string,
}

export default Menu
