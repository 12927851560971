import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { useMetadata } from "../data/useMetadata"
import Card from "../components/Card"
import Layout from "../components/Layout"
import PropTypes from "prop-types"

export const query = graphql`
  {
    cityCollections: allContentfulCity(sort: { order: ASC, fields: order }) {
      nodes {
        title
        slug
      }
    }
  }
`

const Explore = ({ data }) => {
  const { cityCollections } = data
  const cities = cityCollections.nodes

  const { siteUrl } = useMetadata()
  return (
    <Layout>
      <GatsbySeo canonical={`${siteUrl}/explore`} />
      <Card className="my-8 mx-6 lg:mx-auto lg:max-w-5xl">
        <Card.Content>
          <h1 className="text-center text-2xl font-bold uppercase leading-normal text-gray-800">
            找律師 - 全台最大律師查詢系統
          </h1>
          <div className="mt-2 mb-6 text-center uppercase leading-normal text-gray-500">
            請選擇您所在的縣市
          </div>
          <div className="grid grid-cols-2 gap-6 lg:grid-cols-4">
            {cities.map(city => {
              const { slug, title } = city
              return (
                <Link key={slug} to={`/city/${slug}`}>
                  <div className="flex h-16 w-full cursor-pointer items-center justify-center rounded border border-gray-300 text-center hover:bg-gold-200 hover:text-gold-900">
                    {title}
                  </div>
                </Link>
              )
            })}
          </div>
        </Card.Content>
      </Card>
    </Layout>
  )
}

Explore.propTypes = {
  data: PropTypes.object,
}

export default Explore
