import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"

const Privacy = ({ data }) => {
  const {
    contentfulPrivacyPolicy: {
      image: { gatsbyImageData },
      content: { content },
    },
  } = data

  return (
    <Layout>
      <div className="bg-gray-50 pb-6 sm:pb-8 lg:pb-12">
        <div className="container mx-auto flex max-w-3xl py-3 px-6 text-gray-400 text-gray-500 sm:py-6 md:px-0">
          <Link to="/">首頁</Link>
          <div className="px-4">/</div>
          <Link to="/privacy" className="text-gray-700">
            隱私權政策
          </Link>
        </div>
        <div className="mx-auto max-w-3xl bg-white py-6 shadow-md sm:py-8 lg:rounded-lg">
          <h1 className="text-center text-2xl font-bold text-gray-700">
            隱私權政策
          </h1>
          <GatsbyImage
            alt="privacy-bg"
            image={gatsbyImageData}
            className="mt-8 mb-2"
            objectFit={"cover"}
          />
          <div className="mx-auto px-6 sm:px-8">
            <ReactMarkdown className="markdown">{content}</ReactMarkdown>
          </div>
        </div>
      </div>
    </Layout>
  )
}

Privacy.propTypes = {
  data: PropTypes.object,
}

export default Privacy

export const pageQuery = graphql`
  query {
    contentfulPrivacyPolicy(slug: { eq: "privacy" }) {
      image {
        gatsbyImageData
      }
      content {
        content
      }
    }
  }
`
