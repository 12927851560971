import { graphql, useStaticQuery } from "gatsby"

// 給律師的資訊 邀請律師加入平台
export const useLawyer = () => {
  const data = useStaticQuery(
    graphql`
      query GetLawyer {
        contentfulContactInformation(type: { eq: "lawyer" }) {
          type
          lineAt
          title
          nameOne
          nameTwo
          gtmClass
          content {
            content
          }
        }
      }
    `
  )
  return { lawyerContactInformation: data.contentfulContactInformation }
}
