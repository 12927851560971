import { graphql, useStaticQuery } from "gatsby"

export const usePost = () => {
  return useStaticQuery(
    graphql`
      query postQuery {
        latestPosts: allContentfulPost(
          filter: { isPublic: { eq: true } }
          sort: { order: DESC, fields: updatedAt }
          limit: 9
        ) {
          nodes {
            id
            slug
            title
            keyWords
            excerpt {
              excerpt
            }
            topic {
              slug
            }
            tag {
              slug
            }
            cover {
              title
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                width: 345
                height: 230
              )
            }
          }
        }
      }
    `
  )
}
