import { graphql, useStaticQuery } from "gatsby"

export const useFooter = () => {
  return useStaticQuery(
    graphql`
      query GetFooterData {
        topics: allContentfulTopic {
          nodes {
            id
            slug
            title
          }
        }
        relatedLinks: allContentfulRelatedLink(
          sort: { fields: order, order: ASC }
        ) {
          nodes {
            name
            url
            id
          }
        }
      }
    `
  )
}
