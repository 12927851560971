import { graphql, useStaticQuery } from "gatsby"
export const useFixedCTA = () => {
  const data = useStaticQuery(
    graphql`
      {
        allContentfulFixedCta {
          nodes {
            nameOne
            nameThree
            nameTwo
            nameFour
          }
        }
      }
    `
  )
  const ctaContent = data.allContentfulFixedCta.nodes[0]
  return { ctaContent }
}
