import React, { useEffect } from "react"
import ContactButton from "./ContactButton"
import Footer from "./Footer"
import Navbar from "./Navbar"
import SEO from "./SEO"
import PropTypes from "prop-types"

const Layout = ({ location, children, className }) => {
  let pathname = ""
  if (location) {
    pathname = location.pathname
  }

  // 檢查現在與上次關閉彈窗是否已經超過一天
  useEffect(() => {
    const lastCloseTime = localStorage.getItem("popup_close_timestamp")
    const curTime = new Date().getTime()
    if (curTime - lastCloseTime > 24 * 60 * 60 * 1000) {
      localStorage.removeItem("popup_count")
      localStorage.removeItem("popup_close_timestamp")
      localStorage.removeItem("popup_type")
      localStorage.removeItem("popup_open")
    }
  }, [])

  return (
    <>
      <SEO />
      <div className="flex h-screen flex-col">
        <Navbar pathname={pathname} />
        <main className="mt-16 flex-grow">{children}</main>
        <ContactButton pathname={pathname} className={className} />
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Layout
