import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight"
import { useMetadata } from "../data/useMetadata"
import ReactMarkdown from "react-markdown"
import Breadcrumb from "../components/Breadcrumb"
import Card from "../components/Card"
import Layout from "../components/Layout"
import TagsCloud from "../components/TagsCloud"
import PropTypes from "prop-types"

export const pageQuery = graphql`
  query topicAllQuery($slug: String!) {
    contentfulTopic(slug: { eq: $slug }) {
      title
      slug
      description
      seo {
        metaTitle
        metaDescription
      }
      cover {
        title
        file {
          url
        }
      }
      tag {
        id
        title
        slug
        description
        icon {
          title
          file {
            url
          }
        }
        topic {
          slug
          title
        }
        post {
          id
          title
          slug
          isPublic
          isOnTopicAll
          excerpt {
            excerpt
          }
          cover {
            title
            gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              width: 560
              height: 380
            )
          }
        }
      }
    }
  }
`

const TopicAllTemplate = ({ data, location }) => {
  const { contentfulTopic: topic } = data
  const tags = topic.tag

  const { siteUrl } = useMetadata()

  const canonical = `${siteUrl}/blog/${topic.slug}/all`

  const crumbs = [
    { pathname: `/`, crumbLabel: "首頁" },
    {
      pathname: `/blog/${topic.slug}/all`,
      crumbLabel: topic.title,
    },
  ]

  return (
    <Layout location={location}>
      <GatsbySeo
        title={topic.title}
        description={
          (topic.seo && topic.seo.metaDescription) || topic.description
        }
        canonical={canonical}
        openGraph={{
          title: (topic.seo && topic.seo.metaTitle) || topic.title,
          description:
            (topic.seo && topic.seo.metaDescription) || topic.description,
          url: canonical,
          images: [
            {
              url: `https:${topic.cover.file.url}`,
              width: 800,
              height: 600,
              alt: topic.title,
            },
          ],
        }}
      />
      <div className="bg-gray-50">
        <Breadcrumb crumbs={crumbs} />
        <div className="container mx-auto">
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-12 lg:col-span-9">
              <h1 className="my-10 text-center text-3xl font-bold text-gray-500">
                所有{topic.title}文章
              </h1>
              {tags &&
                tags.map(tag => {
                  const posts = tag.post
                  return (
                    <div
                      key={tag.id}
                      className="mb-12 w-full rounded-lg bg-white p-6 shadow-lg"
                    >
                      <div className="grid grid-cols-8">
                        <div className="col-span-2 md:col-span-1">
                          <img
                            src={`https:${tag.icon.file.url}`}
                            alt={tag.icon.title}
                            loading="lazy"
                            width="96"
                            height="96"
                          />
                        </div>
                        <div className="col-span-6 md:col-span-7">
                          <h2 className="text-2xl font-bold text-gold-500">
                            {tag.title}
                          </h2>
                          <p className="text-base text-gray-800">
                            {tag.description}
                          </p>
                        </div>
                      </div>
                      <hr className="my-6 border-gold-500" />
                      <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                        {posts &&
                          posts.map(
                            post =>
                              post.isOnTopicAll &&
                              post.isPublic && (
                                <Link
                                  key={post.id}
                                  to={`/blog/${topic.slug}/${tag.slug}/${post.slug}`}
                                >
                                  <div className="grid grid-cols-8 md:grid-cols-1">
                                    <div className="col-span-3 rounded md:col-span-1">
                                      <GatsbyImage
                                        image={post.cover.gatsbyImageData}
                                        alt={post.cover.title}
                                      />
                                    </div>
                                    <div className="col-span-5 ml-3 py-3 md:col-span-1 md:ml-0 md:py-0">
                                      <p className="my-0 text-base font-bold text-gray-800 md:my-4 md:text-xl">
                                        {post.title}
                                      </p>
                                      <ReactMarkdown
                                        className="h-12 overflow-hidden text-base text-gray-500 md:block md:h-auto"
                                        unwrapDisallowed={true}
                                        disallowedElements={["link"]}
                                      >
                                        {post.excerpt.excerpt}
                                      </ReactMarkdown>
                                    </div>
                                  </div>
                                </Link>
                              )
                          )}
                      </div>
                      <div className="mt-6 text-right text-base font-bold text-gray-800">
                        <Link to={`/blog/${topic.slug}/${tag.slug}`}>
                          更多{tag.title}相關文章
                          <FaAngleRight className="mb-1 inline" size={16} />
                        </Link>
                      </div>
                    </div>
                  )
                })}
            </div>
            <div className="hidden lg:col-span-3 lg:block">
              <p className="my-10 text-center text-3xl font-bold opacity-0">{`1`}</p>
              <Card>
                <Card.Title>{topic.title}法律常識</Card.Title>
                <Card.Content className="lg:pt-0">
                  {tags && <TagsCloud tags={tags} />}
                </Card.Content>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

TopicAllTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default TopicAllTemplate
