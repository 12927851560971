import React, { useEffect } from "react"
import Modal from "react-modal"
import { StaticImage } from "gatsby-plugin-image"
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle"
import { FaPlus } from "@react-icons/all-files/fa/FaPlus"
import PropTypes from "prop-types"
import qrcode from "../images/@074lrexe.svg"

const PopUpModal = ({ modalIsOpen, setIsOpen }) => {
  const closeModal = async () => {
    let popCount = Number(localStorage.getItem("popup_count")) || 0
    const triggerDuration = 20 * 1000

    setIsOpen(false)
    // 關閉後間隔一定的時間再把彈窗狀態設為false，保證這段時間不會重複觸發
    setTimeout(() => {
      localStorage.setItem("popup_open", "false")
    }, triggerDuration)

    // 關閉時如果彈窗次數未滿2次，則在一定的時間後主動再次開啟
    if (popCount < 2) {
      setTimeout(() => {
        popCount += 1
        setIsOpen(true)
        localStorage.setItem("popup_type", "time")
        localStorage.setItem("popup_count", popCount.toString())
      }, triggerDuration)
    }

    // 紀錄關閉彈窗的timestamp
    const curTimestamp = new Date().getTime()
    localStorage.setItem("popup_close_timestamp", curTimestamp.toString())
  }

  const checkList = [
    "免費諮詢",
    "全台70位合作律師",
    "隱私保障",
    "瀏覽人次超過1,000萬",
  ]

  const checkContent = checkList.map((text, index) => (
    <div className="mr-4 flex items-center justify-center" key={index}>
      <FaCheckCircle className="mr-2 h-4 w-4 text-lgreen-light" />
      <p className="text-gray-600">{text}</p>
    </div>
  ))

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [modalIsOpen])

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,.5)",
          display: "block",
          position: "fixed",
          top: 0,
          zIndex: 30,
        },
        content: {
          borderRadius: "8px",
          padding: "0",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <div
        className="custom-height pop-up-modal max-w-lg overflow-y-auto"
        data-test-id="popUp-modal"
      >
        {/* head */}
        <div className="fixed top-0 z-10 flex w-full items-center justify-between bg-gray-100 py-4 px-6">
          <p className="text-2xl font-bold text-gray-700">需要專業協助嗎？</p>
          <div
            className="h-4 w-4 shrink-0 rotate-45 transform cursor-pointer text-gray-300 hover:text-gray-400"
            onClick={closeModal}
          >
            <FaPlus className="h-full w-full" />
          </div>
        </div>
        {/* body */}
        <div className="mb-[4.5rem] mt-16 py-4 px-6 lg:mb-48">
          <StaticImage
            src="../images/img_consult_blue.jpg"
            alt="consult"
            className="mb-6 w-full"
          />
          <p className="mb-6 text-center text-xl font-bold text-gray-700">
            加入我們的LINE官方帳號，立即開始免費諮詢！
          </p>
          <div className="mx-auto flex flex-wrap gap-y-2 px-14 sm:px-16">
            {checkContent}
          </div>
        </div>
        {/* footer */}
        <div className="fixed bottom-0 w-full bg-gray-100 py-4">
          <img
            src={qrcode}
            alt="line_qrcode"
            className="mx-auto hidden h-40 w-40 lg:block"
          />
          <div className="block flex items-center justify-center lg:hidden">
            <a
              href="https://line.me/R/ti/p/%40074lrexe"
              target="_blank"
              rel="noopener noreferrer"
              className="pop-up-btn contact rounded-md bg-lgreen-default py-2 px-4 font-bold text-white transition duration-150 ease-in-out hover:bg-lgreen-light"
            >
              我想解決法律問題
            </a>
          </div>
        </div>
      </div>
    </Modal>
  )
}

PopUpModal.propTypes = {
  modalIsOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}

export default PopUpModal
