import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"

const Article = ({ post, index, displayNumber, isVertical }) => {
  const {
    slug,
    title,
    topic: { slug: topicSlug },
    tag: { slug: tagSlug, title: tagTitle },
    excerpt: { excerpt },
    cover: { gatsbyImageData },
  } = post
  const postNumber = index < 9 ? `0${index}` : index
  const tagLink = `/blog/${topicSlug}/${tagSlug}`
  const postsLink = `${tagLink}/${slug}`
  return (
    <div className={`mx-auto ${isVertical ? "" : "sm:flex"}`}>
      <p
        className={`mb-3 text-4xl font-bold text-gold-200 ${
          displayNumber ? "sm:hidden" : "hidden"
        }`}
      >
        {postNumber}
      </p>
      <Link to={postsLink} className={isVertical ? "" : "sm:mr-12"}>
        <GatsbyImage
          alt={title}
          image={gatsbyImageData}
          className={`mb-3 w-full ${isVertical ? "" : "sm:mb-0 sm:w-80"}`}
        />
      </Link>
      <div className="max-w-[37rem]">
        <p
          className={`mb-3 text-4xl font-bold text-gold-200 ${
            displayNumber ? "hidden sm:block" : "hidden"
          }`}
        >
          {postNumber}
        </p>
        <Link to={tagLink}>
          <p className="mb-3 inline-block font-bold text-gold-600 hover:text-gold-700">
            {tagTitle}
          </p>
        </Link>
        <Link to={postsLink}>
          <p className="mb-3 text-xl font-bold text-gray-700 hover:underline">
            {title}
          </p>
        </Link>
        <Link to={postsLink}>
          <ReactMarkdown
            disallowedElements={["a", "strong"]}
            unwrapDisallowed={true}
            className="text-gray-600 line-clamp-3 hover:text-gray-700"
          >
            {excerpt}
          </ReactMarkdown>
        </Link>
      </div>
    </div>
  )
}

Article.propTypes = {
  post: PropTypes.object,
  index: PropTypes.number,
  displayNumber: PropTypes.bool,
  isVertical: PropTypes.bool,
  children: PropTypes.object,
}

export default Article
