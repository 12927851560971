import React from "react"
import Modal from "./Modal"
import { usePeople } from "../data/usePeople"
import { useLawyer } from "../data/useLawyer"
import PropTypes from "prop-types"
import { useMetadata } from "../data/useMetadata"
import { FaLine } from "@react-icons/all-files/fa/FaLine"
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle"
import { useFixedCTA } from "../data/useFixedCTA"

const ContactButton = ({ pathname, className = "" }) => {
  const isLanding = pathname === "/lawyers/landing"
  const { isTestingSite, isCloneB } = useMetadata()
  const { peopleContactInformation } = usePeople()
  const { lawyerContactInformation } = useLawyer()
  let data = isLanding ? lawyerContactInformation : peopleContactInformation
  const { gtmClass, nameOne, nameTwo, lineAt } = data

  // todo ABTest結束後修改掉cloneB角落cta連結
  const lineLink = isCloneB ? "https://lin.ee/JkA1CUR" : lineAt
  const RoundedButton = () => {
    return (
      <div className="text-center">
        <a
          href={lineLink}
          target="_blank"
          rel="noopener noreferrer"
          className="corner_button block lg:hidden"
        >
          <div
            className={`${gtmClass} animation fixed right-6 bottom-6 z-10 flex h-24 w-24 cursor-pointer items-center justify-center rounded-full bg-gold-400 shadow transition duration-150 ease-in-out hover:bg-gold-600 md:right-8 md:bottom-8 md:h-28 md:w-28 lg:right-16 lg:bottom-16`}
          >
            <div className="font-bold leading-relaxed tracking-widest text-white">
              <p className={`md:text-2xl ${gtmClass}`}>{nameOne}</p>
              <p className={`md:text-base ${className} ${gtmClass}`}>
                {nameTwo}
              </p>
            </div>
          </div>
        </a>
        <div className="corner_button hidden lg:block">
          <Modal isCornerBtn={true} pathname={pathname} isAnimation={true}>
            <p className={`text-2xl ${gtmClass}`}>{nameOne}</p>
            <p className={`text-base ${className} ${gtmClass}`}>{nameTwo}</p>
          </Modal>
        </div>
      </div>
    )
  }

  const FixBottomButton = () => {
    const {
      ctaContent: { nameOne, nameTwo, nameThree, nameFour },
    } = useFixedCTA()
    const benefits = [nameTwo, nameThree, nameFour]
    const benefitsList = benefits.map((text, index) => {
      return (
        <div className="flex items-center text-white" key={index}>
          <FaCheckCircle className="mr-1" />
          <p className="text-sm font-medium lg:text-base">{text}</p>
        </div>
      )
    })
    return (
      <div className="fixed bottom-0 z-10 flex w-full flex-col justify-center bg-gray-600 px-6 py-3 sm:px-8 lg:right-24 lg:max-w-fit lg:rounded-t-md lg:p-6">
        <a
          // todo ABTest結束後修改掉hard code連結
          href="https://lin.ee/ayP1jGZ"
          target="_blank"
          rel="noopener noreferrer"
          className="contact corner_button btn-normal lg:btn-medium mx-auto inline-block flex cursor-pointer items-center rounded-lg bg-lgreen-default text-center font-bold leading-normal text-white shadow transition duration-150 ease-in-out hover:bg-lgreen-light focus:outline-none"
        >
          <FaLine className="mr-2.5 h-6	w-6" />
          <span>{nameOne}</span>
        </a>
        <div className="mx-auto mt-2 flex gap-x-2">{benefitsList}</div>
      </div>
    )
  }
  const ctaButton =
    isTestingSite && !isLanding ? <FixBottomButton /> : <RoundedButton />

  return <>{ctaButton}</>
}

ContactButton.propTypes = {
  data: PropTypes.any,
  pathname: PropTypes.string,
  className: PropTypes.string,
}

export default ContactButton
