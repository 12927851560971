import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

const Author = ({ author, className }) => {
  const { name, description, image } = author
  const { description: authorDescription } = description
  const { gatsbyImageData } = image

  return (
    <div
      className={`rounded-lg bg-white p-6 text-center leading-normal shadow-md ${className}`}
    >
      <GatsbyImage
        alt={name}
        image={gatsbyImageData}
        className="z-10 mb-4 h-36 w-36 rounded-full"
      />
      <p className="mb-2 text-xl font-bold text-gray-700">{name}</p>
      <ReactMarkdown className="text-center text-gray-500 lg:text-left lg:line-clamp-4">
        {authorDescription}
      </ReactMarkdown>
    </div>
  )
}

Author.propTypes = {
  author: PropTypes.object,
  className: PropTypes.string,
}

export default Author
